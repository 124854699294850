// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

